import { Fragment, useState, useEffect } from 'react';
import { 
  Box,
  AppBar,
  useMediaQuery,
  useScrollTrigger
} from '@mui/material';
import Terms from './views/Terms';
import Support from './views/Support';
import DataRequest from './views/DataRequest';
// import Landing from './views/Landing';
// import Auth from './views/Auth';
// import { General } from './views/Account';
// import { Leaderboard } from './views/Leaderboard';
// import Topbar from './layouts/Main/components/Topbar';
import Container from './components/Container';
import { useTheme } from '@mui/material/styles';
import {
  Grid
} from '@mui/material';
import logo from './assets/logo.png';
import ccc from './assets/phrase.png';


function App() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });
  const [route, setRoute] = useState('');


  useEffect(() => {
    setRoute(window.location.pathname);
  }, [])

  return (
    <Fragment>
      {/* <AppBar
        position={'sticky'}
        sx={{
          top: 0,
          // backgroundColor: trigger ? theme.palette.background.paper : '#FFBB18',
          backgroundColor: "#FFBB18"
        }}
        elevation={trigger ? 1 : 0}
      >

      </AppBar> */}
      {route !== '/support' && route !== '/legal' && route !== '/privacy' && route !== '/terms' && route !== '/datarequest' && route !== '/dataRequest' && route !== '/data-request' && <div className="App">
        <header className="App-header">
          <div style={{paddingTop: '100px'}}>
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <img src={ccc} id="phrase" alt="community concious collective" />
        </header>
      </div>}
      {route === '/support' && <Support />}
      {(route === '/datarequest' || route === '/dataRequest' || route === '/data-request') && <DataRequest />}
      {(route === '/legal' || route === '/privacy' || route === '/terms') && <Terms />}
    </Fragment>
  );
}

export default App;
