import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from '../../../../assets/logo-dark.png';
import '../../../../App.css';

const mock = [
  {
    title: '1. What information do we collect?',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    title: '2. How do we use your information?',
    description:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
  },
  {
    title: '3. Will your information be shared with anyone?',
    description:
      'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
  },
  {
    title: '4. Do we use cookies or other tracking technologies?',
    description:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat',
  },
  {
    title: '5. Is your information transferred internationally?',
    description:
      'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains.',
  },
  {
    title: '6. How long do we keep your information?',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    title: '7. What are your privacy rights?',
    description:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
  },
  {
    title:
      '8. How can you review, update or delete the data we collect from you?',
    description:
      'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
  },
];

const PrivacySection = ({ title, description }) => {
  return (
    <Box maxWidth="100%">
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
        }}
      >
        {title}
      </Typography>
      <Typography component={'p'} color={'text.secondary'}>
        {description}
      </Typography>
    </Box>
  );
};

PrivacySection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Content = () => {
  return (
    <>
      <div style={{textAlign: 'center'}}>
        <img src={logo} style={{padding: '1rem'}} className="App-logo" alt="logo" />
      </div>
      <Box width="100%">
        <Box>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                Eqwity, Inc. ("us", "we", or "our") operates the Eqwity.com websites, Eqwity app, and widget(s)(the "Service"). 
                This content informs you of our terms and policies regarding the collection, use and disclosure of Personal Information 
                when you use our Service. We will not use or share your information with anyone except as described in this Privacy Policy. 
                We use your Personal Information for providing and improving the Service. By using the Service, you agree to the collection 
                and use of information in accordance with this policy.
            </Typography>

            <Typography variant="h4" mb="20px" paddingY="20px">
                Terms of Use
            </Typography>

            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                By accessing the Eqwity mobile app and/or website at http://eqwity.com, 
                you are agreeing to be bound by these terms of service, all applicable laws 
                and regulations, and agree that you are responsible for compliance with any 
                applicable local laws. If you do not agree with any of these terms, you are 
                prohibited from using or accessing this site. The materials contained in this 
                app or website are protected by applicable copyright and trademark law.
            </Typography>

            <Typography>Use License</Typography>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                Permission is granted to temporarily download one copy of the materials 
                (information or software) on Eqwity's app and websites for personal, non-commercial 
                transitory viewing only. This is the grant of a license, not a transfer of title, and 
                under this license you may not: 1 - modify or copy the materials 2 - use the materials 
                for any commercial purpose, or for any public display commercial or non-commercial 3 - 
                attempt to decompile or reverse engineer any software contained on Musterd, Inc.'s website 4 - 
                remove any copyright or other proprietary notations from the materials; or 5 - 
                transfer the materials to another person or "mirror" the materials on any other server.
                This license shall automatically terminate if you violate any of these restrictions and 
                may be terminated by Eqwity, Inc at any time. Upon terminating your viewing of these
                materials or upon the termination of this license, you must destroy any downloaded materials 
                in your possession whether in electronic or printed format.
            </Typography>

            <Typography>Disclaimer</Typography>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                We collect information that your phone or browser sends whenever you visit or use our Service 
                ("Log Data"). This Log Data may include information such as your computer or phone's Internet Protocol 
                ("IP") address, browser type, browser version, phone type, the pages of our Service that you visit, the 
                time and date of your visit, the time spent on those pages and other statistics.
            </Typography>

            <Typography>Limitations</Typography>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                In no event shall Eqwity, Inc or its suppliers be liable for any damages (including, without limitation, 
                damages for loss of data or profit, or due to business interruption) arising out of the use or inability 
                to use the materials on Eqwity's app or website, even if Eqwity, Inc or an Eqwity, Inc 
                authorized representative has been notified orally or in writing of the possibility of such damage. 
                Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability 
                for consequential or incidental damages, these limitations may not apply to you.
            </Typography>

            <Typography>Accuracy</Typography>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                The materials appearing on Eqwity, Inc's app and website could include technical, typographical, or photographic
                errors. Eqwity, Inc does not warrant that any of the materials on its website or app are accurate, complete 
                or current. Eqwity, Inc may make changes to the materials contained on its website or app at any time without 
                notice. However, Eqwity, Inc does not make any commitment to update the materials.
            </Typography>

            <Typography>Links</Typography>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                Eqwity, Inc has not reviewed all of the sites linked to its website and app and is not responsible for the contents 
                of any such linked site. The inclusion of any link does not imply endorsement by Eqwity, Inc of the site. Use of any 
                such linked website is at the user's own risk.
            </Typography>

            <Typography>Modifications</Typography>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                Eqwity, Inc may revise these terms of service or privacy policy for its website, apps, widgets, and et cetera at any 
                time without notice. By using this website or app you are agreeing to be bound by the then current version of these terms 
                of service.
            </Typography>

            <Typography>Governing Law</Typography>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                These terms and conditions are governed by and construed in accordance with the laws of Georgia and you irrevocably submit 
                to the exclusive jurisdiction of the courts in that State or location.
            </Typography>

            <Typography variant="h4" mb="20px" paddingY="20px">
                Privacy Policy
            </Typography>

            <Typography>Information Collection & Use</Typography>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to 
                contact or identify you. Personally identifiable information may include, but is not limited to, your name, phone number, 
                other information ("Personal Information").
            </Typography>

            <Typography>Log Data</Typography>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                We collect information that your phone or browser sends whenever you visit our Service ("Log Data"). This Log Data may include 
                information such as your computer or phone's Internet Protocol ("IP") address, browser type, browser version, phone type, the 
                pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics, and more.
            </Typography>

            <Typography>Cookies</Typography>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from 
                a web site or app and stored on your computer's hard drive. We use "cookies" to collect information. You can instruct your browser to 
                refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some 
                portions of our Service.
            </Typography>

            <Typography>Security</Typography>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method 
                of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot 
                guarantee its absolute security.
            </Typography>

            <Typography>Age Restriction and Privacy</Typography>
            <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
                Our Service is not for anyone under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from children under 18. 
                If you are a parent or guardian and you are aware that your Children has provided us with Personal Information, please contact us. If we discover that 
                a Child under 18 has provided us with Personal Information, we will delete such information from our servers immediately.
            </Typography>
          
        </Box>
    </Box>
    </>
  );
};

export default Content;
