import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';
import { TextareaAutosize, Button } from '@mui/base';
import logo from '../../../../assets/logo-dark.png';
import '../../../../App.css';


const Content = () => {

  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    max-width: 600px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );

  const sendHelpRequest = () => {
    alert('An issue occured. Please email your request to support@eqwity.com')
  }

  return (
    <>
      <div style={{textAlign: 'center'}}>
        <img src={logo} style={{padding: '1rem'}} className="App-logo" alt="logo" />
      </div>
      <Box width="100%" height="100vh">
        <Box textAlign="center">
          <Typography variant="h4" mb="20px" paddingY="10px">
            Support
          </Typography>
          <StyledTextarea
            minRows={7}
            placeholder="How may we help?"
            style={{marginBottom: '20px'}}
          />
          <div>
            <Button onClick={sendHelpRequest} style={{backgroundColor: '#000', padding: '5px', borderRadius: '5px', width: '100%', maxWidth: '600px', color: '#fff'}}>send</Button>
          </div>
        </Box>
    </Box>
    </>
  );
};

export default Content;
