import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Grid,
  Box,
  Divider,
  AppBar,
} from '@mui/material';

import useScrollTrigger from '@mui/material/useScrollTrigger';

import Container from '../../components/Container';
import TopNav from '../../components/TopNav';

import { Topbar, Sidebar, Footer } from './components';

import pages from '../navigation';

const Main = ({ children, setCurrentView, colorInvert = false, bgcolor = '#FFBB18' }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  return (
    <Grid container>
      {/* <AppBar
        position={'sticky'}
        sx={{
          top: 0,
          backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
        }}
        elevation={trigger ? 1 : 0}
      >
        <Container paddingY={1}>
          <Topbar
            setCurrentView={setCurrentView}
            onSidebarOpen={handleSidebarOpen}
            pages={pages}
            colorInvert={trigger ? false : colorInvert}
          />
        </Container>
      </AppBar> */}
      {/* <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      /> */}
      <Grid item container xs={12}>
        {children}
        <Divider />
      </Grid>

      <Grid item container p={2.5} style={{backgroundColor: "#000"}}>
        <Footer />
      </Grid>
    </Grid>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
};

export default Main;
