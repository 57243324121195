import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from '../../../../assets/logo-dark.png';
import '../../../../App.css';


const PrivacySection = ({ title, description }) => {
  return (
    <Box maxWidth="100%">
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
        }}
      >
        {title}
      </Typography>
      <Typography component={'p'} color={'text.secondary'}>
        {description}
      </Typography>
    </Box>
  );
};

PrivacySection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Content = () => {
  return (
    <>
      <div style={{textAlign: 'center'}}>
        <img src={logo} style={{padding: '1rem'}} className="App-logo" alt="logo" />
      </div>
      <Box width="100%">
        <Box>

          <Typography variant="h4" mb="20px" paddingY="20px">
              Data Requests
          </Typography>

          <Typography variant="body1" fontWeight="400" fontFamily="helvetica" mb="20px">
              Data requests such as account deletion can be made by logging in to your account via 
              the mobile app. Then, tap the menu icon, tap 'My Account', and you will see the 
              option to delete your account and its associated data. 
          </Typography>

          <img style={{width: '100%'}} src={require("../../../../assets/data-request.jpeg")} />

        </Box>
    </Box>
    </>
  );
};

export default Content;
