import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Main from '../../layouts/Main';
import Container from '../../components/Container';
import { ContactCard, Content } from './components';

const DataRequest = () => {
  const theme = useTheme();

  return (
    <Main>
      <Container>
        <Box p="20px" boxShadow={4} borderRadius={2}>
                <Content />
              {/* <Grid item xs={12} md={3}>
                <Box
                  position={'sticky'}
                  top={theme.spacing(10)}
                  className={'sticky'}
                >
                  <ContactCard />
                </Box>
              </Grid> */}
        </Box>
      </Container>
    </Main>
  );
};

export default DataRequest;
